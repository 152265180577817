<form [formGroup]="formGroup" (ngSubmit)="onSubmitForm()">
  <div class="formgrid grid p-fluid">
    <div class="col-12 md:col-6">
      <app-basic-input
        [label]="moduleFieldString + '.name.label' | translate: 'Name'"
        [placeholder]="moduleFieldString + '.name.placeholder' | translate: 'Enter Name'"
        [control]="formGroup?.controls?.name"
        [viewMode]="fieldViewMode"
      ></app-basic-input>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.owner.label' | translate: 'Owner'"
        [placeholder]="moduleFieldString + '.owner.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.owner"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.manager.label' | translate: 'Manager'"
        [placeholder]="moduleFieldString + '.manager.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.manager"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-user-and-group-selector
        [label]="moduleFieldString + '.approver.label' | translate: 'Approver'"
        [placeholder]="moduleFieldString + '.approver.placeholder' | translate: 'Search...'"
        [principleMode]="true"
        [control]="formGroup?.controls?.approver"
        [optionValue]="undefined"
        [multi]="false"
        [viewMode]="fieldViewMode"
      >
      </app-user-and-group-selector>
      <!-- <app-user-and-group-tree-selector [principleModeSelection]="'SELECTION'"
                [label]="'Approvers'"
                [placeholder]="'Search...'"
                [control]="formGroup?.controls?.approvers"
                [multi]="true"
                [viewMode]="fieldViewMode"
                [selectedEntities]="data?.approvers"
                [useResponsibilityMode]="true">
            </app-user-and-group-tree-selector> -->
    </div>
    <div class="col-12">
      <app-target-code-selector
        [label]="moduleFieldString + '.type.label' | translate: 'Program Type'"
        [placeholder]="moduleFieldString + '.type.placeholder' | translate: 'Search Program Types'"
        [control]="formGroup?.controls?.type"
        [viewMode]="'create'"
        [inlineMode]="false"
        [appendTo]="'body'"
        [multi]="false"
        [optionLabel]="'name'"
        [optionValue]="undefined"
        [customProjectionFields]="null"
        [targetTypes]="['AUDIT_PROGRAM_TYPE']"
        [dataKey]="'code'"
      >
      </app-target-code-selector>
    </div>
    <div class="col-12 md:col-6">
      <app-date-input
        [label]="moduleFieldString + '.startDate.label' | translate: 'Start Date'"
        [placeholder]="'2022-11-22'"
        [name]="'startDate'"
        [showIcon]="true"
        [control]="formGroup?.controls?.startDate"
        [viewMode]="fieldViewMode"
        [maxDate]="formGroup?.controls?.endDate?.value || null"
      ></app-date-input>
    </div>
    <div class="col-12 md:col-6">
      <app-date-input
        [label]="moduleFieldString + '.endDate.label' | translate: 'Due Date '"
        [placeholder]="'2022-11-22'"
        [name]="'endDate'"
        [showIcon]="true"
        [control]="formGroup?.controls?.endDate"
        [viewMode]="fieldViewMode"
        [minDate]="formGroup?.controls?.startDate?.value || null"
      ></app-date-input>
    </div>
    <!-- <div class="col-12 md:col-6">
            <app-drop-down-input [label]="'Audit Universe Status'"
                [placeholder]="'Select Item'"
                [name]="'dropdown'"
                [optionLabel]="'label'"
                [optionValue]="'value'"
                [items]="auditUniverseStatuses"
                [optionValue]="'value'"
                [control]="formGroup?.controls?.auditUniverseStatus"
                [viewMode]="fieldViewMode"
                [badgeView]="true"></app-drop-down-input>

        </div> -->
    <div class="col-12">
      <app-text-editor
        [height]="'100px'"
        [name]="'textarea'"
        [label]="moduleFieldString + '.description.label' | translate: 'Description'"
        [placeholder]="moduleFieldString + '.description.placeholder' | translate: 'Enter Text...'"
        [control]="formGroup?.controls?.description"
        [viewMode]="fieldViewMode"
        [advanced]="true"
      ></app-text-editor>
    </div>
  </div>
  <ng-content></ng-content>
</form>
