import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuditProgramDto, BaseForm } from '@shared/classes';
import { ViewModeService } from '@shared/services';
import { AuditProgramDataService } from '../../services/data/audit-program-data.service';

@Component({
  selector: 'app-audit-program-item-form',
  templateUrl: './audit-program-item-form.component.html',
  styleUrls: ['./audit-program-item-form.component.scss'],
})
export class AuditProgramItemFormComponent extends BaseForm<AuditProgramDto> implements OnInit {
  contextVariables: any[] = [];

  constructor(
    public viewModeService: ViewModeService,
    private requestService: AuditProgramDataService
  ) {
    super(viewModeService, 'AUDIT_PROGRAM');
  }

  ngOnInit(): void {}

  getData() {
    return this.viewModeService.viewMode == 'create' ? this.data : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.patchFormData({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      startDate: new FormControl(null, Validators.required),
      endDate: new FormControl(null, Validators.required),
      type: new FormControl(null, Validators.required),
      owner: new FormControl(null, Validators.required),
      manager: new FormControl(null, Validators.required),
      approver: new FormControl(null, Validators.required),
      description: new FormControl(null),
    });
  }
}
